$button-spacing: 0.25rem;

.neural-numbers-training-component {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #f2f2f2;
  padding: 0.5rem;
  display: flex;
  justify-content: center;

  .controls {
    margin: 0 $button-spacing * -1;
    align-self: center;
  }

  .image-count {
    align-self: center;
  }

  .training-progress {
    display: flex;
    padding: 0 2rem;
    align-self: center;
  }

  .accuracy {
    display: flex;
  }

  .accuracy-label {
    margin-right: 0.5rem;
    align-self: center;
    font-weight: bold;
    text-align: right;
  }

  .accuracy-value {
    line-height: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    width: 2.1em;
    text-align: right;
    padding: 0.3rem 0.5rem;
    color: #049404;

    &[data-ranking='bad'] {
      color: #d31616;
    }

    &[data-ranking='mediocre'] {
      color: #c48606;
    }
  }

  .image-count {
    display: flex;
    margin-right: 1rem;
  }

  .image-count-label {
    margin-right: 0.5em;
    font-weight: bold;
    text-align: right;
    align-self: center;
  }

  .image-count-value {
    line-height: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    width: 3em;
    display: inline-block;
  }

  .btn {
    font-size: 0;
    border: 2px solid #b6b6b6;
    border-radius: 50%;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
    margin: 0 $button-spacing;
    cursor: pointer;

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .start-pause-btn {
    width: 4rem;
    height: 4rem;
    background-image: url("../../assets/img/resume.svg");
    background-position: 60% center;
  }

  &.running {
    .start-pause-btn {
      background-image: url("../../assets/img/pause.svg");
      background-position: center center;
    }
  }

  .step-btn {
    width: 3.3rem;
    height: 3.3rem;
    background-image: url("../../assets/img/step.svg");
    background-size: 55%;
    background-position: 42% center;
  }

  .reset-btn {
    width: 3.3rem;
    height: 3.3rem;
    background-image: url("../../assets/img/reset.svg");
    background-size: 45%;
    background-position: center 40%;
  }
}
