.bars {
  display: flex;
  padding: 0;
  margin: 0;
  position: relative;
  flex-direction: row;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.bars {
  &.bars-vertical,
  &.vertical {
    flex-direction: column;
  }
}

.barcontainer {
  position: relative;
  height: 100%;
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include media-breakpoint-down(sm) {
    height: 20px;
    width: 120px;
    justify-content: flex-start;
    flex-direction: row;
  }
}

.vertical,
.bars-vertical {
  .barcontainer {
    height: 10%;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
}

.bartxt {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  /*bottom: 4px;*/
  /*position: absolute;*/
  padding-bottom: 7px;
  padding-top: 7px;

  @include media-breakpoint-down(sm) {
    order: 1;
    font-size: 16px;
    text-align: left;
    padding: 0;
  }
}

// Used in the exhibit (training tab)
.vertical .bartxt {
  padding-right: 15px;
  padding-left: 10px;
  width: 10px;
  padding-top: 10px;
}

// Used in the component
.bars-vertical .bartxt {
  font-size: 18px;
  padding: 0 15px 0 10px;
  margin-top: -2px;
  width: 1em;
  text-align: left;
}

.bar {
  /*position: absolute;*/
  margin-left: auto;
  margin-right: auto;
  /*bottom: 10%;*/
  width: 20px;
  box-sizing: border-box;
  /*top: calc(100% - var(--probability)*90%);*/
  height: calc(var(--probability) * 90%);
  background-color: $color-yellow;
  border: 2px solid #333007;
  border-radius: 0;
  min-height: 0;

  @include media-breakpoint-down(sm) {
    order: 2;
    height: 20px;
    width: calc(var(--probability) * 100px);
    min-width: 0;
    margin: 0 0 0 6px;
  }
}

// Used in the exhibit (training tab)
.vertical .bar {
  margin: auto auto auto 0;
  height: 20px;
  width: calc(var(--probability) * 90%);
  min-width: 0;
}

// Used in the component
.bars-vertical .bar {
  margin: auto auto auto 0;
  height: 20px;
  width: calc(var(--probability) * (100% - 3.4em));
  min-width: 0;
}

.bar.highlighted {
  background: $color-yellow;
}
