$input-box-size: 200px;
$input-box-border: 8px;

.neural-numbers-component {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  height: $input-box-size + $input-box-border * 2;

  .stage {
    position: relative;
    display: none;
    flex: 0;
    align-items: center;
    margin-right: 50px;

    @include media-breakpoint-down(sm) {
      margin-right: 10px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .stage-input {
    display: flex;
  }

  .break {
    height: 0;
    flex-basis: 100%;
  }

  &.with-normalizer {
    .stage-normalize {
      display: flex;
    }
  }

  &.with-bars {
    .stage-bars {
      display: flex;
    }
  }

  &.with-output {
    .stage-output {
      display: flex;
    }
  }

  @include media-breakpoint-down(sm) {
    &.with-output.with-bars {
      .stage-output {
        display: none;
      }
    }
  }

  .drawcanvas, .normalizecanvas {
    pointer-events: all !important;
    filter: invert(1);
    opacity: 0.5;
    width: $input-box-size;
    height: $input-box-size;
  }

  .input-canvas-wrapper {
    width: $input-box-size;
    height: $input-box-size;
    border: $input-box-border dashed #666;
    border-radius: $input-box-border;
    box-sizing: content-box;

    canvas {
      touch-action: none;
    }
  }

  .input-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: $input-box-size;
    height: $input-box-size;
    margin: $input-box-border;
    padding: 15px;
    box-sizing: border-box;
    text-align: center;
    vertical-align: middle;
    font-size: 25px;
    color: $color-gray;
    pointer-events: none;
    display: flex;
    align-items: center;
    transition: opacity 0.3s;
    opacity: 0;
  }

  .stage-input.background {
    .input-placeholder {
      opacity: 0.8;
    }
  }

  .normalize-canvas-wrapper {
    position: relative;
    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      pointer-events: none;
    }
  }

  .normalizecanvas {
    width: $input-box-size * 0.75;
    height: $input-box-size * 0.75;
    opacity: 1;

    @include media-breakpoint-down(sm) {
      width: 100px;
      height: 100px;
    }
  }

  .bars {
    height: $input-box-size;
    width: $input-box-size;

    @include media-breakpoint-down(sm) {
      width: 100px;
    }

    .bar {
      background-color: $color-yellow;
      border: 2px solid #333007;
      border-radius: 0;
      min-height: 0;
    }
  }

  .output-wrapper {
    position: relative;
    width: $input-box-size;
    height: $input-box-size;
    background: radial-gradient($input-box-size * 0.55, $color-yellow, transparent);

    @include media-breakpoint-down(sm) {
      width: 100px;
      background: radial-gradient(55px, $color-yellow, transparent);
    }

    &:before {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      transition: opacity 1s;
      opacity: 1;
    }

    &.solved {
      &:before {
        opacity: 0;
      }
    }
  }

  .output {
    position: relative;
    width: $input-box-size;
    height: $input-box-size;
    font-size: $input-box-size * 0.8;
    color: #16214b;
    text-align: center;
    line-height: $input-box-size;

    @include media-breakpoint-down(sm) {
      width: 100px;
      font-size: 80px;
    }
  }
}

.neural-numbers-component-bad {
  .bars .bar {
    background-color: $color-red;
  }
}

.neural-numbers-component-mediocre {
  .bars .bar {
    background-color: $color-orange;
  }
}

.neural-numbers-component.large {
  height: auto;

  .input-canvas-wrapper {
    width: 350px;
    height: 350px;
  }

  .drawcanvas {
    height: 350px;
    width: 350px;
  }

  .input-placeholder {
    width: 350px;
    height: 350px;
  }
}
